.navbar {
  font-family: 'Raleway', sans-serif;

  justify-content: space-evenly;
  width: 100%;
}

.navbar-wrapper {
  display: grid;
  grid-template-columns: 75% 25%;
  /* color: #0e3039; */
}
.banner {
  font-size: 4rem;
  justify-self: center;
  align-self: center;
  /* color: #0b032d; */
  color: #ffb997;
}

.icons {
  width: 25%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  align-content: space-around;
}

.about,
.projects {
  justify-self: center;
  align-self: center;
  padding: 2px;
  text-decoration: none;
  color: navy;
}
.about:hover {
  border-bottom: 2px solid #f08ed2;
}
.iconLink:hover {
  border-bottom: 2px solid #f08ed2;
}
.iconLink {
  padding: 0 2px;
}

.linkHome {
  text-decoration: none;
  color: navy;
}

.menuWrapper {
  justify-self: center;
  align-self: center;
}
