/* Global Styles */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  /* color: #333; */
}

.App {
  max-width: 960px;
  margin: 0 auto;
}

ul {
  list-style: none;
}

@media (max-width: 700px) {
  .container,
  .container2,
  .wrapper {
    display: inline;
  }

  .App {
    text-align: center;
  }
}

/* Dots */
.dot_1 {
  max-width: 500px;
}

.dot_13 {
  max-width: 500px;
}
