.aboutMe {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 3em 3em 4em;
  margin: 0 5%;
  border-radius: 20px;
  background-image: linear-gradient(45deg, #b9be74, #bcd0d7, #aaddec);
  opacity: 0.9;
}

.title {
  text-align: right;
  /* padding: 2em 3em 1em; */
}

.descriptionAbout {
  padding: 1em 3em 1em;
  align-self: center;
  justify-self: center;
}

.textSection {
  /* border: 1px solid black; */
  border-radius: 20px;
  background-image: linear-gradient(45deg, #b9be74, #bcd0d7, #aaddec);
  opacity: 0.9;
  padding: 3em 3em 4em;
  align-self: center;
  justify-self: center;
}

.textSection:hover {
  box-shadow: 0 52px 74px rgb(0 0 0/12%);
  transform: translate3d(0, -5px, 0);
}
