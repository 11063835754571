.homeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 2rem;
}
.homeWrapper {
  padding: 2rem;
}
h1,
h2,
h3,
h4,
p {
  font-family: 'Raleway';
  color: #494d5f;
}
.heroText {
  justify-content: center;
}
.heroTitle {
  padding: 4rem 2rem;
  font-size: 40px;
}
.heroImg {
  background-color: #dcd9d4;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    radial-gradient(
      at 50% 0%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 50%
    );
  background-blend-mode: soft-light, screen;
  min-height: 400px;
  border-radius: 20px;
  align-items: center;
}

.dot {
  max-width: 100px;
  max-height: 150px;
}
